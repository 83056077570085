import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Zap } from 'lucide-react';
import axios from 'axios';
import axiosInstance from '../../axiosInstance';
import { useAuth } from '../../context/AuthProvider';

export const Plans = () => {
  const { isAuthenticated, user } = useAuth();
  const [membershipTiers, setMembershipTiers] = useState([]);
  
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [activeMembershipMessage, setActiveMembershipMessage] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // New state to track token changes
 

  // Fetch membership data
  const fetchMembershipData = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/membership/packages');
      const packages = response?.data?.packages;

      // Get the monthly price (from the ONE_MONTH package)
      const monthlyPrice = packages.ONE_MONTH.price;

      // Calculate savings for each package and add them to the package data
      const transformedTiers = Object.keys(packages).map((pkg, index) => {
        const colors = [
          { accentColor: 'text-gray-600', gradientFrom: 'from-gray-700', gradientTo: 'to-gray-700' },
          { accentColor: 'text-blue-600', gradientFrom: 'from-blue-400', gradientTo: 'to-blue-700' },
          { accentColor: 'text-purple-600', gradientFrom: 'from-purple-400', gradientTo: 'to-purple-700' }
        ];

        const { price, dur_in_month } = packages[pkg];

        // Calculate the total price if paid monthly
        const totalIfMonthly = monthlyPrice * dur_in_month;

        // Calculate savings in dollars
        const savingsAmount = totalIfMonthly - price;

        return {
          package_key: pkg,
          ...packages[pkg],
          savings: savingsAmount.toFixed(2),
          savingsAmount: savingsAmount.toFixed(2),
          ...colors[index % colors.length],
          highlight: index === 2,
        };
      });

      setMembershipTiers(transformedTiers);
    } catch (error) {
      console.error('Failed to fetch membership data:', error);
    }
  }, []);

  // Effect to handle token changes and authentication
  useEffect(() => {
 
    fetchMembershipData();

 ;
  }, [ fetchMembershipData]);

  // Handle when a user selects a plan
  const handleChoosePlan = async (planType) => {
   
    if(!isAuthenticated)
    {
      setSelectedPlan(planType);
      setShowLoginModal(true); // Show the login modal
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post(
        '/membership/create-checkout-session',
        { type: planType },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      
      const publicKey = response.data?.data?.publicKey;
      const sessionId = response.data?.data?.sessionId;
      if (response.data?.success && response.data?.message === "You already have an active membership") {
        setActiveMembershipMessage(response.data?.message);
      } else {
        const stripe = await loadStripe(publicKey);
        await stripe.redirectToCheckout({ sessionId });
      }
      
    } catch (error) {
      console.error('Failed to initiate Stripe Checkout:', error);
    } finally {
      setLoading(false);
    }
  };
  const MembershipCard = ({ tier, onChoosePlan }) => {
    const { 
      name, 
      price, 
      duration, 
      savings,
      accentColor, 
      gradientFrom, 
      gradientTo, 
      highlight,
      package_key,
      savingsAmount
    } = tier;
  
    return (
      <motion.div 
        whileHover={{ scale: 1.05, boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' }}
        className={`relative overflow-hidden rounded-2xl 
          bg-white shadow-2xl border border-gray-200
          transform transition-all duration-500 ease-in-out
          ${highlight ? 'scale-105 border-purple-300' : ''}`}
      >
        {/* Rest of the MembershipCard implementation remains the same */}
        <div className="p-6 relative">
          <div className="text-center">
            <h2 className={`text-2xl font-bold mb-2 ${accentColor}`}>
              {name} Plan
            </h2>
            
            <div className="mb-6">
              <div className="flex justify-center items-baseline">
                <span className="text-4xl font-extrabold text-gray-900">
                  ${price.toFixed(2)}
                </span>
                <span className="text-gray-500 ml-2 text-sm">/ {duration}</span>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <p className={`text-sm font-medium ${accentColor}`}>
                Save {savingsAmount}$ with Unlimited Stock Screening
              </p>
              <p className="text-xs text-gray-500 mt-1">
                Full access to AI-powered stock screening tools
              </p>
            </div>
            
            <button 
              onClick={() => onChoosePlan(package_key)}
              className={`w-full py-3 rounded-xl 
                bg-gradient-to-r ${gradientFrom} ${gradientTo}
                text-white font-semibold
                hover:opacity-90 
                transition-all duration-300
                shadow-md hover:shadow-lg
              `}
            >
              Choose Plan
            </button>
          </div>
        </div>
      </motion.div>
    );
  };
  const closeLoginModal = () => {
    setShowLoginModal(false);
    setSelectedPlan(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#0d0d0d] to-[#434343] py-16 px-4 pt-24">
      <div className="container mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-white mb-4">
            AI-Powered Stock Screening Plans
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Leverage the power of artificial intelligence to make smarter, faster investment decisions with our fully automated stock screener.
          </p>
        </div>
        {loading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full text-center">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Processing...</h2>
              <div className="flex justify-center items-center">
                <div className="w-16 h-16 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
              </div>
              <p className="text-gray-600 mt-4">Please wait while we process your request.</p>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {membershipTiers.map((tier) => (
            <MembershipCard 
              key={tier.name} 
              tier={tier} 
              onChoosePlan={handleChoosePlan} 
            />
          ))}
        </div>
        
        {/* Attractive Login Modal */}
        {showLoginModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
              <div className="text-center">
                <h2 className="text-3xl font-semibold text-gray-800 mb-4">Log in to Subscribe</h2>
                <p className="text-lg text-gray-600 mb-4">
                  You need to log in to choose a subscription plan. Please log in to access exclusive packages.
                </p>
                <button 
                  onClick={closeLoginModal} 
                  className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-2 rounded-full font-semibold shadow-md hover:opacity-90 transition-all duration-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        {activeMembershipMessage && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
              <div className="text-center">
                <h2 className="text-3xl font-semibold text-gray-800 mb-4">Notice</h2>
                <p className="text-lg text-gray-600 mb-4">
                  {activeMembershipMessage}
                </p>
                <button 
                  onClick={() => setActiveMembershipMessage('')}  // Close the modal
                  className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-2 rounded-full font-semibold shadow-md hover:opacity-90 transition-all duration-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
