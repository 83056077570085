import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from '../constants';
import LoginModal from './LoginModal';
import ChangePasswordModal from './ChangePasswordModal';
import axiosInstance from '../../axiosInstance';
import { Menu, X, User, Settings, LogOut, Mail, KeyRound } from 'lucide-react';
import { useAuth } from '../../context/AuthProvider';

const ProfileModal = ({ isOpen, onClose, user }) => {
  const { remainingAttempts } = useAuth(); // Access remaining attempts and the fetch function

  // useEffect(() => {
  //   if (isOpen) {
  //     fetchUserAttempts();  // Fetch attempts when the modal is opened
  //   }
  // }, [isOpen, fetchUserAttempts]);
  if (!isOpen) return null;
 const membershipDateCheck =  new Date(user?.membership?.end_date) < new Date() 
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
    <div className="bg-white rounded-lg shadow-xl w-96 p-6 relative">
      <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
        <X size={24} />
      </button>

      <div className="flex flex-col items-center mb-6">
        <img src={'/person.png'} alt="Profile" className="w-24 h-24 rounded-full mb-4" />
        <h2 className="text-2xl font-bold">{user.name}</h2>
        <p className="text-gray-600">{user.email}</p>
      </div>

      <div className="space-y-6">
        <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-blue-100 to-blue-300 rounded-xl shadow-md transform transition-all hover:scale-105 hover:shadow-lg">
          <User className="w-6 h-6 text-blue-500" />
          <span className="text-lg font-medium text-gray-800">{user?.firstname} {user?.lastname}</span>
        </div>

        <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-green-100 to-green-300 rounded-xl shadow-md transform transition-all hover:scale-105 hover:shadow-lg">
          <Mail className="w-6 h-6 text-green-500" />
          <span className="text-lg font-medium text-gray-800">{user?.email}</span>
        </div>

        <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-yellow-100 to-yellow-300 rounded-xl shadow-md transform transition-all hover:scale-105 hover:shadow-lg">
          <Settings className="w-6 h-6 text-green-500" />
          <div className="flex flex-col">
            {user?.membership ? (
              <>
                {(user.membership.type === 'ONE_MONTH' && !membershipDateCheck) && (
                  <p className="text-sm text-green-700 mt-1">
                    Enjoy 1 Month of premium access! Stay on top of the latest market trends and analysis.
                  </p>
                )}
                {(user.membership.type === 'SIX_MONTH' && !membershipDateCheck) && (
                  <p className="text-sm text-green-700 mt-1">
                    You've subscribed for 6 Months. Great choice! Enjoy extended access to all features.
                  </p>
                )}
                {(user.membership.type === 'ONE_YEAR' && !membershipDateCheck) && (
                  <p className="text-sm text-green-700 mt-1">
                    You have 1 Year of full access! Enjoy the entire range of premium features for a full year.
                  </p>
                )}
                <div className="flex flex-col mt-4">
                  <span className="text-sm text-gray-600">
                    <strong>Start Date:</strong> {new Date(user.membership.start_date).toLocaleDateString()}
                  </span>
                  <span className="text-sm text-gray-600">
                    <strong>End Date:</strong> {new Date(user.membership.end_date).toLocaleDateString()}
                  </span>
                  <span className="text-sm text-red-700 mt-1">
                    {new Date(user.membership.end_date) < new Date() 
                      ? "Your membership has expired."
                      : `Your membership expires in ${Math.ceil((new Date(user.membership.end_date) - new Date()) / (1000 * 3600 * 24))} days.`}
                  </span>
                </div>
              </>
            ) : (
              <p className="text-sm text-red-700 mt-1">
                You don't have a current membership. Consider subscribing to access all premium features.
              </p>
            )}
          </div>
        </div>

        {/* Display User Attempts */}
        <div className="flex items-center space-x-4 p-4 bg-gradient-to-r from-purple-100 to-purple-300 rounded-xl shadow-md transform transition-all hover:scale-105 hover:shadow-lg">
          <KeyRound className="w-6 h-6 text-purple-500" />
          <div className="text-lg font-medium text-gray-800">
            <p>Your remaining attempts: <strong>{remainingAttempts === 0 > remainingAttempts? remainingAttempts -1 : remainingAttempts }</strong></p>
          </div>
        </div>

      </div>
    </div>
  </div>
  );
};

export const Navbar = () => {
  const [selectedTab, setSelectedTab] = useState(ROUTES.HOME_PATH);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [navbarDropdown,setNavbarDropDown] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { isAuthenticated, user, logout,fetchUserAttempts } = useAuth();
  const location = useLocation();
  useEffect(() => {
    if(isAuthenticated)
    {
      fetchUserAttempts();
    }
    setNavbarDropDown(false);
    const newCurrentPath = location.pathname;
    setSelectedTab(newCurrentPath);
    setCurrentPath(newCurrentPath);
  }, [location]);

 

  const handleLogin = () => {
    setNavbarDropDown(false);
    setIsLoginModalOpen(false);
  };

  const handleLogout = () => {
    logout();
    setSelectedTab(ROUTES.HOME_PATH);
    navigate(ROUTES.HOME_PATH);
  };


  const navLinks = [
    { path: ROUTES.HOME_PATH, label: 'HOME' },
    { path: ROUTES.SOTD_PATH, label: 'SOTD' },
    { path: ROUTES.ABOUT_PATH, label: 'ABOUT' },
    { path: ROUTES.CONTACT_PATH, label: 'CONTACT' },
    { path: ROUTES.PLANS_PATH, label: 'MEMBERSHIP' }
  ];
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) && 
        !dropdownButtonRef.current.contains(event.target)
      ) {
        setNavbarDropDown(false);
      }
    };

    // Add event listener when dropdown is open
    if (navbarDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarDropdown]);
  // Render method remains largely the same, with modifications in the profile/authentication sections
  return (
    <>
      <nav className="bg-gradient-to-r from-[#0d0d0d] to-[#434343] shadow-md sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex items-center">
              <img 
                src={'/whiteLogo.png'} 
                alt="Company Logo"
                className="h-10 w-auto cursor-pointer" 
                onClick={() => {
                  setSelectedTab(ROUTES.HOME_PATH);
                  navigate(ROUTES.HOME_PATH);
                }}
              />
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <button 
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-white hover:text-blue-300 transition duration-300"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex space-x-4">
              {navLinks.map((link) => (
                <Link 
                  key={link.path}
                  to={link.path} 
                  className={`text-white hover:text-blue-300 transition duration-300 
                    ${selectedTab === link.path ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
                  onClick={() => setSelectedTab(link.path)}
                >
                  {link.label}
                </Link>
              ))}
            </div>

             {/* Authentication/Profile Section */}
             <div className="hidden md:block">
      {isAuthenticated ? (
        <div className="relative">
          <button 
            ref={dropdownButtonRef}
            onClick={() => setNavbarDropDown(!navbarDropdown)}
            className="flex items-center space-x-2 bg-white text-black px-4 py-2 rounded hover:bg-blue-600 hover:text-white transition-all duration-300"
          >
            <img 
              src={'/person.png'} 
              alt="Profile" 
              className="w-8 h-8 rounded-full"
            />
            <span className="capitalize">{user?.name}</span>
          </button>
          
          {navbarDropdown && (
            <div 
              ref={dropdownRef}
              className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50"
            >
              <div className="py-1">
                <button
                  onClick={() => {
                    setIsProfileModalOpen(true);
                    setNavbarDropDown(false);
                  }}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <User className="mr-2 w-4 h-4" /> Profile
                </button>
                <button
                  onClick={() => {
                    setIsChangePasswordModalOpen(true);
                    setNavbarDropDown(false);
                  }}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <KeyRound className="mr-2 w-4 h-4" /> Change Password
                </button>
                <button
                  onClick={() => {
                    handleLogout();
                    setNavbarDropDown(false);
                  }}
                  className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                >
                  <LogOut className="mr-2 w-4 h-4" /> Logout
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <button 
          onClick={() => setIsLoginModalOpen(true)}
          className="px-6 py-2 bg-white text-black rounded hover:bg-blue-600 hover:text-white transition-all duration-300"
        >
          Login
        </button>
      )}
    </div>
          </div>

          {/* Mobile Menu Dropdown */}
          {isMenuOpen && (
            <div className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-[#434343]">
                {navLinks.map((link) => (
                  <Link
                    key={link.path}
                    to={link.path}
                    className={`block text-white hover:bg-gray-700 px-3 py-2 rounded-md 
                      ${selectedTab === link.path ? 'bg-gray-900' : ''}`}
                    onClick={() => {
                      setSelectedTab(link.path);
                      setIsMenuOpen(false);
                    }}
                  >
                    {link.label}
                  </Link>
                ))}

                {/* Mobile Authentication Section */}
                {isAuthenticated ? (
                <div>
                  <button
                    onClick={() => {
                      setIsProfileModalOpen(true);
                      setIsMenuOpen(false);
                    }}
                    className="block w-full text-left text-white hover:bg-gray-700 px-3 py-2 rounded-md"
                  >
                    My Profile
                  </button>
                  <button
                    onClick={() => {
                      setIsChangePasswordModalOpen(true);
                      setIsMenuOpen(false);
                    }}
                    className="block w-full text-left text-white hover:bg-gray-700 px-3 py-2 rounded-md"
                  >
                    Change Password
                  </button>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsMenuOpen(false);
                    }}
                    className="block w-full text-left text-red-500 hover:bg-gray-700 px-3 py-2 rounded-md"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsLoginModalOpen(true);
                    setIsMenuOpen(false);
                  }}
                  className="block w-full text-white hover:bg-gray-700 px-3 py-2 rounded-md"
                >
                  Login
                </button>
              )}
              </div>
            </div>
          )}
        </div>
      </nav>

      {/* Login Modal */}
      <LoginModal
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)} 
        onLogin={handleLogin}
      />

      {/* Profile Modal */}
      {user && (
        <ProfileModal 
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
          user={user}
        />
      )}

      {/* Change Password Modal */}
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
      />
    </>
  );
};


