import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ROUTES } from './core';
import { Home, SOTD, About, Contact,Plans,SuccessPayment,FailsPayment,ResetPassword } from './modules';
import { Navbar } from './core/components/Navbar';

const App = () => {
  
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path={ROUTES.HOME_PATH} element={<Home />} />
        <Route path={ROUTES.SOTD_PATH} element={<SOTD />} />
        <Route path={ROUTES.ABOUT_PATH} element={<About />} />
        <Route path={ROUTES.CONTACT_PATH} element={<Contact />} />
        <Route path={ROUTES.PLANS_PATH} element={<Plans />} />
        <Route path={ROUTES.PAYMENT_SUCCESS_PATH} element={<SuccessPayment />} />
        <Route path={ROUTES.PAYMENT_FAILS_PATH} element={<FailsPayment />} />
        <Route path={ROUTES.RESET_PASSWORD_PATH} element={<ResetPassword />} />
        {/* <Route path="/success" element={<SuccessPage />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
