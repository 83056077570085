import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axiosInstance from '../../axiosInstance';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  border: none;
`;

const AccessControl = ({ onAccessGranted }) => {
  const [user,setUser] = useState();
  let token = localStorage.getItem('token');
  const navigate = useNavigate();
 useEffect(()=>{
 
  if(token){
  
    fetchUserProfile();
  }

 },[])
 const fetchUserProfile = async () => {
  try {
    const response = await axiosInstance.get('/profile/', {
      headers: { Authorization: `Bearer ${token}` }
    });
    setUser({
      name: `${response?.data?.user?.firstname} ${response?.data?.user?.lastname}`,
      ...response?.data?.user
    });
  } catch (error) {
    console.error("Error fetching profile", error);
  }
};
 const handlePayingCustomer = ()=>{
  if((!token || (user && !user?.membership && user?.membership?.status !== "ACTIVE")))
  {
    navigate('/plans');
  }else{
    onAccessGranted(false);
  }
 }
  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Select Access Level</h2>
        <Button onClick={() => handlePayingCustomer()}>Paying Customer</Button>
        <Button onClick={() => onAccessGranted(true)}>Guest</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AccessControl;
