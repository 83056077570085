import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { X, User, Mail, Lock, Eye, EyeOff, RefreshCw } from 'lucide-react';
import axiosInstance from '../../axiosInstance'; // Import axiosInstance
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import { useAuth } from '../../context/AuthProvider';

const LoginModal = ({ isOpen, onClose,onLogin }) => {
    const { login } = useAuth();
    const [mode, setMode] = useState('login'); // 'login', 'register', 'forgotPassword', 'otpVerify'
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        otp: ''
    });
    const [errors, setErrors] = useState({});
    const [otpTimer, setOtpTimer] = useState(0);
    const [apiError, setApiError] = useState(''); // To store API error messages
    const navigate = useNavigate();
    // Validation functions
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        const re = /^.{6,}$/;  // At least 6 characters (no special requirements)
        return re.test(password);
    };
    

    const validateForm = () => {
        const newErrors = {};
      
        // Email validation
      if(mode !== 'login')
      {
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!validateEmail(formData.email)) {
            newErrors.email = "Invalid email format";
        }
      }

        // Mode-specific validations
        if (mode === 'register') {
            // First name validation
            if (!formData.firstname) {
                newErrors.firstname = "First name is required";
            } else if (formData.firstname.length < 2) {
                newErrors.firstname = "First name must be at least 2 characters";
            }

            // Last name validation
            if (!formData.lastname) {
                newErrors.lastname = "Last name is required";
            } else if (formData.lastname.length < 2) {
                newErrors.lastname = "Last name must be at least 2 characters";
            }

            // Username validation
            if (!formData.username) {
                newErrors.username = "Username is required";
            } else if (formData.username.length < 3) {
                newErrors.username = "Username must be at least 3 characters";
            }

            // Password validation
            if (!formData.password) {
                newErrors.password = "Password is required";
            } else if (!validatePassword(formData.password)) {
                newErrors.password = "Password must be 8+ chars, include uppercase, lowercase, number";
            }

            // Confirm password validation
            if (formData.password !== formData.confirmPassword) {
                newErrors.confirmPassword = "Passwords do not match";
            }
        }

        if (mode === 'login') {
            // Basic password presence check for login
            if (!formData.username) {
                newErrors.username = "Username is required";
            } else if (formData.username.length < 3) {
                newErrors.username = "Username must be at least 3 characters";
            }

            if (!formData.password) {
                newErrors.password = "Password is required";
            }
        }

        // if (mode === 'otpVerify') {
        //     if (!formData.otp || formData.otp.length !== 6) {
        //         newErrors.otp = "OTP must be 6 digits";
        //     }
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        if (errors[name]) setErrors(prev => ({ ...prev, [name]: undefined }));
    };

    const handleSendOtp = async () => {

        if (validateForm()) {
            try {
                const response = await axiosInstance.post('/auth/forgot-password', { email: formData.email });
                if (response.data.success) {
                    setMode('otpVerify');
                    setOtpTimer(60);
                    const timer = setInterval(() => {
                        setOtpTimer(prev => {
                            if (prev <= 1) {
                                clearInterval(timer);
                                return 0;
                            }
                            return prev - 1;
                        });
                    }, 1000);
                } else {
                    setApiError(response.data.message);
                }
            } catch (error) {
                setApiError('Failed to send OTP. Please try again later.');
            }
        }
    };

    const handleSubmit = async (e) => {
        
        setApiError('');
        e.preventDefault();
        if (validateForm()) {
            try {
                let response;
                switch (mode) {
                    case 'login':
                        response = await axiosInstance.post('/auth/login', { username: formData.username, password: formData.password });
                        console.log(response);
                        if (response.data.success) {
                            localStorage.setItem('token',response?.data?.token);
                            login();
                          onLogin();
                        } else {
                            setApiError(response.data.message);
                        }
                        break;

                    case 'register':
                        response = await axiosInstance.post('/auth/register', {
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            username: formData.username,
                            email: formData.email,
                            password: formData.password
                        });
                        if (response.data.success) {
                            console.log('Registration successful:', response.data);
                            setMode('login'); // Switch to login mode after registration
                        } else {
                            setApiError(response.data.message);
                        }
                        break;
                    case 'forgotPassword':
                        response = await axiosInstance.post('/auth/forgot-password', {
                            email: formData.email
                        });
                        if (response.data.success) {
                            console.log('OTP verify:', response.data);
                            setMode('otpVerify'); // Switch to login mode after registration
                        } else {
                            setApiError(response.data.message);
                        }
                        break;
                    case 'otpVerify':
                        setMode('login')
                        break;
                    default:
                        return;
                }
            } catch (error) {
                setApiError(error?.response?.data?.message || 'An error occurred. Please try again later.');
            }
        }
    };

    // Render input with error handling
    const renderInput = (name, type, placeholder, icon, additionalProps = {}) => {
        return (
            <div>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {icon}
                    </div>
                    <input
                        name={name}
                        type={type === 'password' ? (showPassword ? "text" : "password") : type}
                        value={formData[name]}
                        onChange={handleInputChange}
                        className={`w-full pl-10 pr-10 py-2 border rounded-lg transition-all duration-300 ${
                            errors[name] ? 'border-red-500 focus:ring-2 focus:ring-red-300' : 'border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                        }`}
                        placeholder={placeholder}
                        {...additionalProps}
                    />
                    {type === 'password' && (
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 transition-colors"
                        >
                            {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                        </button>
                    )}
                </div>
                {errors[name] && <p className="text-red-500 text-sm mt-1 ml-1">{errors[name]}</p>}
            </div>
        );
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50 p-4"
                >
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ type: "spring", stiffness: 300, damping: 20 }}
                        className="relative w-full max-w-md bg-white rounded-2xl shadow-2xl overflow-hidden"
                    >
                        <div className="relative">
                            <button
                                onClick={onClose}
                                className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 z-10 transition-colors duration-300"
                            >
                                <X className="w-6 h-6" />
                            </button>

                            <div className="p-8">
                                <div className="text-center mb-6">
                                    <h2 className="text-3xl font-bold text-gray-800">
                                        {mode === 'login' && 'Welcome Back'}
                                        {mode === 'register' && 'Create Account'}
                                        {mode === 'forgotPassword' && 'Reset Password'}
                                        {mode === 'otpVerify' && 'Forget Password'}
                                    </h2>
                                    <p className="text-gray-600 mt-2">
                                        {mode === 'login' && 'Sign in to continue'}
                                        {mode === 'register' && 'Join our community today'}
                                        {mode === 'forgotPassword' && 'Enter your email to reset password'}
                                        {mode === 'otpVerify' && 'Check your mailbox'}
                                    </p>
                                </div>

                                {apiError && (
                                    <div className="bg-red-100 text-red-800 p-2 rounded mb-4">
                                        {apiError}
                                    </div>
                                )}

                                <form onSubmit={handleSubmit} className="space-y-4">
                                    {mode === 'forgotPassword' && (
                                        renderInput('email', 'email', 'Email address', <Mail className="h-5 w-5 text-gray-400" />)
                                    )}

                                    {mode === 'otpVerify' && (
                                        <>
                                            <p class="text-center text-lg text-gray-800 font-medium py-4 px-6 rounded-md bg-blue-100 border-l-4 border-blue-500">
  Please check your email! We have sent you a reset password link.
</p>

                                        </>
                                    )}

                                    {(mode === 'login' || mode === 'register') && (
                                        <>
                                            {mode === 'register' && renderInput('firstname', 'text', 'First name', <User className="h-5 w-5 text-gray-400" />)}
                                            {mode === 'register' && renderInput('lastname', 'text', 'Last name', <User className="h-5 w-5 text-gray-400" />)}
                                            {renderInput('username', 'text', 'Username', <User className="h-5 w-5 text-gray-400" />)}
                                            { mode === 'register' && renderInput('email', 'email', 'Email address', <Mail className="h-5 w-5 text-gray-400" />)}
                                            {renderInput('password', 'password', 'Password', <Lock className="h-5 w-5 text-gray-400" />)}
                                            {mode === 'register' && renderInput('confirmPassword', 'password', 'Confirm Password', <Lock className="h-5 w-5 text-gray-400" />)}
                                        </>
                                    )}

                                    <div className="flex items-center justify-between mt-4">
                                         <button
                                            type="submit"
                                            className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                                        >
                                            {mode === 'login' && 'Sign In'}
                                            {mode === 'register' && 'Create Account'}
                                            {mode === 'forgotPassword' && 'Submit'}
                                            {mode === 'otpVerify' && 'Go back to login'}
                                        </button>
                                    </div>
                                </form>

                                <div className="mt-6 text-center">
                                     {mode === 'login' && (
                                        <div className="flex justify-between items-center">
                                            <button
                                                onClick={() => setMode('forgotPassword')}
                                                className="text-blue-600 hover:text-blue-800 text-sm transition-colors"
                                            >
                                                Forgot Password?
                                            </button>
                                            <p>
                                                Don't have an account?{' '}
                                                <button
                                                    onClick={() => setMode('register')}
                                                    className="text-blue-600 hover:text-blue-800 font-semibold transition-colors"
                                                >
                                                    Sign Up
                                                </button>
                                            </p>
                                        </div>
                                    )}
                                    {mode === 'register' && (
                                        <p>
                                            Already have an account?{' '}
                                            <button
                                                onClick={() => setMode('login')}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                Sign In
                                            </button>
                                        </p>
                                    )}
                                    {mode === 'forgotPassword' && (
                                        <p>
                                            Remembered your password?{' '}
                                            <button
                                                onClick={() => setMode('login')}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                Sign In
                                            </button>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default LoginModal;
