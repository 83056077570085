import React from 'react'
import { useNavigate } from 'react-router-dom';
import PaymentStatus from '../../core/components/PaymentStatus';

export const FailsPayment = ()=>{
    const navigate = useNavigate();
    const handleHomeClick = () => {
      // Navigate to home page
      navigate('/');
    };
  
    return (
      <PaymentStatus
        type="failure"
        onHomeClick={handleHomeClick}
      />
    );
  }

