import React from 'react';

const PaymentStatus = ({ type = 'success', onHomeClick }) => {
  const isSuccess = type === 'success';

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-2xl p-8 text-center">
        <div className={`
          w-24 h-24 mx-auto mb-6 rounded-full flex items-center justify-center
          ${isSuccess 
            ? 'bg-green-100 border-4 border-green-500' 
            : 'bg-red-100 border-4 border-red-500'
          }`}
        >
          {isSuccess ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          )}
        </div>

        <h2 className={`
          text-3xl font-bold mb-6
          ${isSuccess ? 'text-green-600' : 'text-red-600'}
        `}>
          {isSuccess ? 'Payment Successful' : 'Payment Failed'}
        </h2>

        <button 
          onClick={onHomeClick}
          className="px-8 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentStatus;