import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosInstance from '../axiosInstance';

// Create the AuthContext
const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
  fetchUserProfile: () => {},
  fetchUserAttempts: () => {},
  reduceUserAttempts: () => {},
  remainingAttempts: 0
});

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [remainingAttempts, setRemainingAttempts] = useState(0);

  // Check authentication on initial load
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      fetchUserProfile();
      fetchUserAttempts();  // Fetch user attempts when authenticated
    }
  }, []);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.get('/profile/', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser({
        name: `${response?.data?.user?.firstname} ${response?.data?.user?.lastname}`,
        ...response?.data?.user
      });
    } catch (error) {
      console.error("Error fetching profile", error);
      // Logout if profile fetch fails
      logout();
    }
  };

  // Function to fetch the number of attempts
  const fetchUserAttempts = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.get('/user-attempt/user-attempts', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRemainingAttempts(response?.data?.remainingAttempts);
    } catch (error) {
      console.error("Error fetching user attempts", error);
    }
  };

  // Function to reduce an attempt
  const reduceUserAttempts = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.post('/user-attempt/reduce-attempts', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRemainingAttempts(response?.data?.remainingAttempts);
    } catch (error) {
      console.error("Error reducing user attempts", error);
    }
  };

  const login = () => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      fetchUserProfile();
      fetchUserAttempts();  // Fetch attempts on login
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    setRemainingAttempts(0); // Reset attempts on logout
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      user, 
      login, 
      logout, 
      fetchUserProfile, 
      fetchUserAttempts, 
      reduceUserAttempts, 
      remainingAttempts 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
