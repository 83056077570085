import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import Logo from '../../WhiteLogo.PNG';
import AccessControl from './AccessControl'; // Adjust this import path accordingly
import ReactPlayer from 'react-player';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';


const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(10px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;


const rotation = keyframes`
0% {
  transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
  animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
}
50% {
  transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
  animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
}
100% {
  transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
  animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
}
`;


const bouncing = keyframes`
0% {
  transform: translateY(-40px);
  animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
}
45% {
  transform: translateY(40px);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
100% {
  transform: translateY(-40px);
  animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
}
`;


const bouncingShadow = keyframes`
0% {
  transform: translateZ(-80px) scale(1.3);
  animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  opacity: 0.05;
}
45% {
  transform: translateZ(0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0.3;
}
100% {
  transform: translateZ(-80px) scale(1.3);
  animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
  opacity: 0.05;
}
`;


const PageContainer = styled.div`
background-color: #1e1e1e;
min-height: 100vh;
font-family: 'Roboto', sans-serif;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
animation: ${fadeIn} 1s ease-in-out;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
`;
const Scene = styled.div`
position: relative;
z-index: 2;
height: 220px;
width: 220px;
display: grid;
place-items: center;
margin-top: -30px;
`;


const CubeWrapper = styled.div`
transform-style: preserve-3d;
animation: ${bouncing} 2s infinite;
`;


const Cube = styled.div`
transform-style: preserve-3d;
transform: rotateX(45deg) rotateZ(45deg);
animation: ${rotation} 2s infinite;
`;


const CubeFaces = styled.div`
transform-style: preserve-3d;
height: 80px;
width: 80px;
position: relative;
transform-origin: 0 0;
transform: translateX(0) translateY(0) translateZ(-40px);
`;


const CubeFace = styled.div`
position: absolute;
inset: 0;
background: #ffffff;
border: solid 1px #ffffff;
&.shadow {
  transform: translateZ(-80px);
  animation: ${bouncingShadow} 2s infinite;
}
&.top {
  transform: translateZ(80px);
}
&.front {
  transform-origin: 0 50%;
  transform: rotateY(-90deg);
}
&.back {
  transform-origin: 0 50%;
  transform: rotateY(-90deg) translateZ(-80px);
}
&.right {
  transform-origin: 50% 0;
  transform: rotateX(-90deg) translateY(-80px);
}
&.left {
  transform-origin: 50% 0;
  transform: rotateX(-90deg) translateY(-80px) translateZ(80px);
}
`;


const LogoImageStyled = styled.img`
position: absolute;
width: 105px;
height: 105px;
z-index: 3;
`;


const LoadingText = styled.div`
color: #ffffff;
font-size: 18px;
margin-top: 5px;
`;


const ButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
flex-direction: column;
`;


const TapToViewText = styled.div`
margin-top: -100px;
color: #ffffff;
font-size: 34px;
margin-bottom: 30px;
`;


const StockOfTheDayText = styled.div`
color: #ffffff;
font-size: 15px;
margin-top: 18px;
`;


const LogoButton = styled.button`
background-color: transparent;
border: 1px solid #ffffff;
border-radius: 28px;
padding: 10px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 160px;
height: 160px;
transition: transform 0.3s ease;
&:hover {
  transform: scale(1.1);
}
`;


const TabContainer = styled.div`
display: flex;
justify-content: center;
margin-top: 20px;
`;


const TabButton = styled.button`
background-color: ${({ active }) => (active ? '#444' : '#333')};
color: white;
border: none;
padding: 15px 30px;
margin: 0 5px;
cursor: pointer;
border-radius: 5px;
font-size: 16px;
transition: background-color 0.3s ease, transform 0.2s ease;
&:hover {
  background-color: #555;
  transform: translateY(-3px);
}
`;


const StockItem = styled.div`
  width: 90%; /* Default responsive width */
  max-width: 600px; /* Restrict maximum width */
  margin: 20px auto; /* Center the item with margin */
  background-color: #2e2e2e;
  padding: 15px; /* Adjust padding to fit content */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.8s ease-in-out;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  ${({ isBlurred }) => isBlurred && `
    filter: blur(10px);
  `}

  @media (min-width: 1024px) and (max-width: 1440px) {
    /* Specific styles for laptop displays */
    width: 95%; /* Make it longer for laptops */
    max-width: 800px; /* Increase maximum width */
    padding: 20px; /* Slightly more padding for larger rectangles */
  }

  @media (max-width: 768px) {
    width: 95%; /* Increase responsiveness for smaller screens */
    padding: 10px; /* Adjust padding for smaller devices */
  }

  @media (max-width: 480px) {
    width: 98%; /* Almost full width for very small screens */
    padding: 8px; /* Adjust padding further for very small devices */
  }
`;






const StockHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;


const CompanyName = styled.div`
font-size: 24px;
color: #ffffff;
font-weight: bold;
transition: color 0.2s ease;
&:hover {
  color: #00ff00;
}
`;


const TickerText = styled.div`
font-size: 16px;
color: #b0b0b0;
margin-top: 5px;
`;


const StockInfo = styled.div`
display: flex;
flex-direction: column;
align-items: flex-end;
`;


const PriceText = styled.div`
font-size: 20px;
color: #fbfcf6;
font-weight: bold;
`;


const ChangePercentText = styled.div`
font-size: 16px;
color: ${({ positive }) => (positive ? '#00ff00' : '#ff0000')};
margin-top: 5px;
`;


const Separator = styled.hr`
border: none;
border-top: 1px solid #444;
margin: 15px 0;
`;


const DetailsText = styled.div`
font-size: 14px;
color: #b0b0b0;
margin-top: 10px;
`;


const AIAnalysisText = styled.div`
  font-size: 14px;
  color: #b0b0b0;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  span {
    &:nth-child(1) {
      filter: none;
    }
    &:nth-child(2) {
      filter: blur(3px);
    }
  }
`;




const StockContentWrapper = styled.div`
margin-top: 100px;
padding: 20px;
${({ isBlurred }) => isBlurred && `
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
`}
`;


const GuestPopup = styled.div`
background-color: #333;
padding: 50px;
border-radius: 30px;
text-align: center;
color: #ffffff;
position: absolute;
top: 60%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 10;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;


const ButtonWrapper = styled.div`
 display: flex;
 flex-direction: column;
 gap: 15px;
 align-items: center;
`;


const GuestViewText = styled.h2`
font-size: 29px;
margin-bottom: 30px;
font-weight: bold;
`;


const WatchAdButton = styled.button`
background-color: white;
color: black;
border: 2px solid white;
padding: 20px 130px;
font-size: 18px;
border-radius: 50px;
cursor: pointer;
transition: all 0.3s ease;
font-weight: bold;


&:hover {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}
`;


const SOTDPlusButton = styled(WatchAdButton)`
 // Use the same styles as WatchAdButton
`;


const FeatureList = styled.ul`
 list-style-type: disc;
 margin-top: 20px;
 color: white;
 text-align: left;
`;


const FeatureListItem = styled.li`
 margin-bottom: 10px;
`;


const AdContainer = styled.div`
 display: ${({ show }) => (show ? 'flex' : 'none')};
 background-color: #000;
 color: #fff;
 width: 100%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 20;
 justify-content: center;
 align-items: center;
 flex-direction: column;
`;


const formatPrice = (price) => {
 if (!price) return 'N/A';
 let formattedPrice = price.toFixed(6);
 while (formattedPrice.endsWith('0') && !formattedPrice.endsWith('.00')) {
   formattedPrice = formattedPrice.substring(0, formattedPrice.length - 1);
 }
 return formattedPrice;
};


export const SOTD = () => {
  const [data, setData] = useState({});
  const [contentLoaded, setContentLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('Day Trading');
  const [loading, setLoading] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Analyzing stock data...');
  const [guestMode, setGuestMode] = useState(null);
  const [showAd, setShowAd] = useState(false);
  const [adWatched, setAdWatched] = useState(false);
  const [ws, setWs] = useState(null);

  const navigate = useNavigate();
  const { isAuthenticated, user,reduceUserAttempts,remainingAttempts } = useAuth();
  const token = localStorage.getItem('token');

  // Function to establish WebSocket connection
  const connectWebSocket = (category = null) => {
    // const wsUrl = new URL(`${process.env.REACT_APP_WS_URL || 'ws://localhost:3000/ws'}`);
    const wsUrl = new URL(`wss://sotdbackend-6de2e4e2a56a.herokuapp.com/ws`);
    if (token) {
      wsUrl.searchParams.append('token', token);
    }
    
    if (category) {
      wsUrl.searchParams.append('category', category);
    }
    
    const newWs = new WebSocket(wsUrl.toString());

    newWs.onopen = () => {
      console.log('WebSocket connected');
      // Send category change message if needed
      if (category) {
        newWs.send(JSON.stringify({
          type: 'change_category',
          category: category
        }));
      }
    };

    newWs.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
      
      switch (receivedData.type) {
        case 'stock_update':
          // console.log('receivedData',receivedData)
          setLoading(false);
          setContentLoaded(true);
           
          if(!isAuthenticated || (user && !user?.membership && user?.membership?.status !== "ACTIVE" && remainingAttempts === 0) )
          {
            handleAccessGranted(true);
          }
          if(isAuthenticated  && remainingAttempts === 0)
          {
            handleAccessGranted(true);
          }
          setData(prevData => {
          
            const category = receivedData.category;
            const newStocks = [...(prevData[category] || [])];
            
            // Only add if not already present
            if (!newStocks.find(stock => stock.ticker === receivedData.data.ticker)) {
              newStocks.push(receivedData.data);
            }
            
            return {
              ...prevData,
              [category]: newStocks
            };
          });
          break;

        case 'complete':
          setContentLoaded(true);
          setLoading(false);
          setTabLoading(false);
          break;

        case 'error':
          console.error('Server error:', receivedData.message);
          setLoading(false);
          setTabLoading(false);
          break;
      }
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
      setLoading(false);
      setTabLoading(false);
    };

    newWs.onclose = () => {
      console.log('WebSocket connection closed');
      setLoading(false);
      setTabLoading(false);
    };

    setWs(newWs);
    return newWs;
  };

  // Effect for loading messages
  useEffect(() => {
    if (loading || tabLoading) {
      const messages = [
        'Analyzing stock data...',
        'Identifying key market trends...',
        'Calculating RSI indicators...',
        'Assessing market volatility...',
        'Optimizing entry and exit points...',
        'Gathering real-time stock updates...',
      ];
      
      let index = 0;
      const messageInterval = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000);

      return () => clearInterval(messageInterval);
    }
  }, [loading, tabLoading]);

  // Effect for handling tab changes
  useEffect(() => {
    if (ws) {
      setTabLoading(true);
      ws.close();
      setData(prevData => ({
        ...prevData,
        [activeTab]: []
      }));
      connectWebSocket(activeTab);
    }
  }, [activeTab]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  const handleInitialLoad = () => {
    setLoading(true);
    reduceUserAttempts();
    connectWebSocket(activeTab);
  };
  const handleAccessGranted = (isGuest) => {
    setGuestMode(isGuest);
  };

  const handleWatchAd = () => {
    setShowAd(true);
  };

  const handleSOTDPlusClick = () => {
    navigate('/plans');
  };

  const handleAdEnded = () => {
    setShowAd(false);
    setAdWatched(true);
  };

 

  const loopTimes = Math.floor(Math.random() * 4) + 1;
  const loopIteration = Array.from({ length: loopTimes });
  const getRandomBoolean = () => Math.random() > 0.5;

  return (
    <PageContainer>
      {!contentLoaded ? (
        <ButtonContainer>
          {loading ? (
            <>
              <Scene>
                <CubeWrapper>
                  <Cube>
                    <CubeFaces>
                      <CubeFace className="shadow" />
                      <CubeFace className="bottom" />
                      <CubeFace className="top" />
                      <CubeFace className="left" />
                      <CubeFace className="right" />
                      <CubeFace className="back" />
                      <CubeFace className="front" />
                    </CubeFaces>
                  </Cube>
                </CubeWrapper>
              </Scene>
              <LoadingText>{loadingMessage}</LoadingText>
            </>
          ) : (
            <>
              <TapToViewText>Tap to view</TapToViewText>
              <LogoButton onClick={() => handleInitialLoad()}>
                <LogoImageStyled src={Logo} alt="Logo" />
              </LogoButton>
              <StockOfTheDayText>Stock of The Day</StockOfTheDayText>
            </>
          )}
        </ButtonContainer>
      ) : (
        <>
          <TabContainer>
            <TabButton 
              active={activeTab === 'Day Trading'} 
              onClick={() => setActiveTab('Day Trading')}
              disabled={tabLoading}
            >
              Day Trading
            </TabButton>
            <TabButton 
              active={activeTab === 'Swing Trading'} 
              onClick={() => setActiveTab('Swing Trading')}
              disabled={tabLoading}
            >
              Swing Trading
            </TabButton>
            <TabButton 
              active={activeTab === 'Long Term'} 
              onClick={() => setActiveTab('Long Term')}
              disabled={tabLoading}
            >
              Long Term
            </TabButton>
          </TabContainer>

          {tabLoading ? (
            <LoadingContainer>
              <Scene>
                <CubeWrapper>
                  <Cube>
                    <CubeFaces>
                      <CubeFace className="shadow" />
                      <CubeFace className="bottom" />
                      <CubeFace className="top" />
                      <CubeFace className="left" />
                      <CubeFace className="right" />
                      <CubeFace className="back" />
                      <CubeFace className="front" />
                    </CubeFaces>
                  </Cube>
                </CubeWrapper>
              </Scene>
              <LoadingText>{loadingMessage}</LoadingText>
            </LoadingContainer>
          ) : (
          <StockContentWrapper isBlurred={guestMode && !adWatched}>
            {activeTab === 'Day Trading' && (
              <div>
                <h2 style={{ color: '#ffffff', textAlign: 'center', marginTop: '20px', fontSize: '34px', fontWeight: 'bold' }}>Day Trading</h2>
                <h3 style={{ color: '#b0b0b0', textAlign: 'center', marginBottom: '40px' }}>Top picks for today</h3>
                <ul>
                  {data && data['Day Trading']?.map((stock, index) => (
                    <StockItem key={index} >
                      <StockHeader>
                        <div>
                          <CompanyName>{index + 1}. {stock.name}</CompanyName>
                          <TickerText>{stock.ticker}</TickerText>
                        </div>
                        <StockInfo>
                          <PriceText>${stock.price ? stock.price : 'N/A'}</PriceText>
                          <ChangePercentText positive={stock.change_percent >= 0}>
                            {stock.change_percent ? stock.change_percent.toFixed(2) : 'N/A'}%
                          </ChangePercentText>
                        </StockInfo>
                      </StockHeader>
                      <Separator />
                      <DetailsText>Volume: {stock.volume ? stock.volume.toLocaleString() : 'N/A'}</DetailsText>
                      <DetailsText>Market Cap: {stock.market_cap ? stock.market_cap.toLocaleString() : 'N/A'}</DetailsText>
                      <DetailsText>P/E Ratio: {stock.pe_ratio ? stock.pe_ratio.toFixed(2) : 'N/A'}</DetailsText>
                      <DetailsText>Entry Point: ${stock.entry_point ? stock.entry_point : 'N/A'}</DetailsText>
                      <DetailsText>Exit Point: ${stock.exit_point ? stock.exit_point : 'N/A'}</DetailsText>
                      <AIAnalysisText isBlurred={guestMode && !adWatched}>
  AI Analysis: {stock.analysis}
</AIAnalysisText>

                    </StockItem>
                  ))}
                  {(data['Day Trading']?.length > 0 && (!isAuthenticated || (user && !user?.membership && user?.membership?.status !== "ACTIVE" && remainingAttempts === 0) || (isAuthenticated  && remainingAttempts === 0))) && loopIteration.map((item, index) => (
                    <StockItem key={index} isBlurred={true}>
                      <StockHeader>
                        <div>
                          <CompanyName>3. Test</CompanyName>
                          <TickerText>TTT</TickerText>
                        </div>
                        <StockInfo>
                          <PriceText>${'N/A'}</PriceText>
                          <ChangePercentText positive={getRandomBoolean()}>
                            {'N/A'}%
                          </ChangePercentText>
                        </StockInfo>
                      </StockHeader>
                      <Separator />
                      <DetailsText>Volume: {'N/A'}</DetailsText>
                      <DetailsText>Market Cap: {'N/A'}</DetailsText>
                      <DetailsText>P/E Ratio: {'N/A'}</DetailsText>
                      <DetailsText>Entry Point: ${'N/A'}</DetailsText>
                      <DetailsText>Exit Point: ${'N/A'}</DetailsText>
                      <AIAnalysisText isBlurred={adWatched}>AI Analysis: Lorem Ipsum is simply dummy text of the printing and typesetting industry.</AIAnalysisText>
                    </StockItem>
                  ))}
                </ul>
              </div>
            )}
            {activeTab === 'Swing Trading' && (
            <div>
              <h2 style={{ color: '#ffffff', textAlign: 'center', marginTop: '20px', fontSize: '34px', fontWeight: 'bold' }}>
                Swing Trading
              </h2>
              <h3 style={{ color: '#b0b0b0', textAlign: 'center', marginBottom: '40px' }}>Top picks for today</h3>
              <ul>
                {data &&
                  data['Swing Trading']?.map((stock, index) => (
                    <StockItem key={index} >
                      <StockHeader>
                        <div>
                          <CompanyName>{index + 1}. {stock.name}</CompanyName>
                          <TickerText>{stock.ticker}</TickerText>
                        </div>
                        <StockInfo>
                          {/* <PriceText>${stock.price ? formatPrice(stock.price) : 'N/A'}</PriceText> */}
                          <PriceText>${stock.price ? stock.price : 'N/A'}</PriceText>
                          <ChangePercentText positive={stock.change_percent >= 0}>
                            {stock.change_percent ? stock.change_percent.toFixed(2) : 'N/A'}%
                          </ChangePercentText>
                        </StockInfo>
                      </StockHeader>
                      <Separator />
                      <DetailsText>Volume: {stock.volume ? stock.volume.toLocaleString() : 'N/A'}</DetailsText>
                      <DetailsText>Market Cap: {stock.market_cap ? stock.market_cap.toLocaleString() : 'N/A'}</DetailsText>
                      <DetailsText>P/E Ratio: {stock.pe_ratio ? stock.pe_ratio.toFixed(2) : 'N/A'}</DetailsText>
                      <DetailsText>Entry Point: ${stock.entry_point ? stock.entry_point : 'N/A'}</DetailsText>
                      <DetailsText>Exit Point: ${stock.exit_point ? stock.exit_point : 'N/A'}</DetailsText>
                      <AIAnalysisText isBlurred={guestMode && !adWatched}>
  AI Analysis: {stock.analysis}
</AIAnalysisText>

                    </StockItem>
                  ))}
                     {(data['Swing Trading']?.length > 0 && (!isAuthenticated || (user && !user?.membership && user?.membership?.status !== "ACTIVE" && remainingAttempts === 0) || (isAuthenticated  && remainingAttempts === 0))) &&  loopIteration.map((item)=>{
                      return  <StockItem isBlurred={true} >
                      <StockHeader>
                        <div>
                          <CompanyName>3. Test</CompanyName>
                          <TickerText>TTT</TickerText>
                        </div>
                        <StockInfo>
                          {/* <PriceText>${stock.price ? formatPrice(stock.price) : 'N/A'}</PriceText> */}
                          <PriceText>${'N/A'}</PriceText>
                          <ChangePercentText positive={getRandomBoolean()}>
                            {'N/A'}%
                          </ChangePercentText>
                        </StockInfo>
                      </StockHeader>
                      <Separator />
                      <DetailsText>Volume: { 'N/A'}</DetailsText>
                      <DetailsText>Market Cap: { 'N/A'}</DetailsText>
                      <DetailsText>P/E Ratio: { 'N/A'}</DetailsText>
                      <DetailsText>Entry Point: ${ 'N/A'}</DetailsText>
                      <DetailsText>Exit Point: ${ 'N/A'}</DetailsText>
                      <AIAnalysisText isBlurred={adWatched}>AI Analysis: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</AIAnalysisText>
                    </StockItem>
                     })}
                  
              </ul>
            </div>
          )}


          {activeTab === 'Long Term' && (
            <div>
              <h2 style={{ color: '#ffffff', textAlign: 'center', marginTop: '20px', fontSize: '34px', fontWeight: 'bold' }}>
                Long Term
              </h2>
              <h3 style={{ color: '#b0b0b0', textAlign: 'center', marginBottom: '40px' }}>Top picks for today</h3>
              <ul>
                {data &&
                  data['Long Term']?.map((stock, index) => (
                    <StockItem key={index} isBlurred={adWatched && (index === 2 || index === 3 || index === 4)}>
                      <StockHeader>
                        <div>
                          <CompanyName>{index + 1}. {stock.name}</CompanyName>
                          <TickerText>{stock.ticker}</TickerText>
                        </div>
                        <StockInfo>
                          {/* <PriceText>${stock.price ? formatPrice(stock.price) : 'N/A'}</PriceText> */}
                          <PriceText>${stock.price ? (stock.price) : 'N/A'}</PriceText>
                          <ChangePercentText positive={stock.change_percent >= 0}>
                            {stock.change_percent ? stock.change_percent.toFixed(2) : 'N/A'}%
                          </ChangePercentText>
                        </StockInfo>
                      </StockHeader>
                      <Separator />
                      <DetailsText>Volume: {stock.volume ? stock.volume.toLocaleString() : 'N/A'}</DetailsText>
                      <DetailsText>Market Cap: {stock.market_cap ? stock.market_cap.toLocaleString() : 'N/A'}</DetailsText>
                      <DetailsText>P/E Ratio: {stock.pe_ratio ? stock.pe_ratio.toFixed(2) : 'N/A'}</DetailsText>
                      <DetailsText>Entry Point: ${stock.entry_point ? stock.entry_point : 'N/A'}</DetailsText>
                      <DetailsText>Exit Point: ${stock.exit_point ? stock.exit_point : 'N/A'}</DetailsText>
                      <AIAnalysisText isBlurred={guestMode && !adWatched}>
  AI Analysis: {stock.analysis}
</AIAnalysisText>

                    </StockItem>
                  ))}
                    {(data['Long Term']?.length > 0 && (!isAuthenticated || (user && !user?.membership && user?.membership?.status !== "ACTIVE" && remainingAttempts === 0) || (isAuthenticated  && remainingAttempts === 0))) &&  loopIteration.map((item)=>{
                      return  <StockItem isBlurred={true} >
                      <StockHeader>
                        <div>
                          <CompanyName>3. Test</CompanyName>
                          <TickerText>TTT</TickerText>
                        </div>
                        <StockInfo>
                          {/* <PriceText>${stock.price ? formatPrice(stock.price) : 'N/A'}</PriceText> */}
                          <PriceText>${'N/A'}</PriceText>
                          <ChangePercentText positive={getRandomBoolean()}>
                            {'N/A'}%
                          </ChangePercentText>
                        </StockInfo>
                      </StockHeader>
                      <Separator />
                      <DetailsText>Volume: { 'N/A'}</DetailsText>
                      <DetailsText>Market Cap: { 'N/A'}</DetailsText>
                      <DetailsText>P/E Ratio: { 'N/A'}</DetailsText>
                      <DetailsText>Entry Point: ${ 'N/A'}</DetailsText>
                      <DetailsText>Exit Point: ${ 'N/A'}</DetailsText>
                      <AIAnalysisText isBlurred={adWatched}>AI Analysis: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</AIAnalysisText>
                    </StockItem>
                     })}
              </ul>
            </div>
          )}
          </StockContentWrapper>
  )}
          {/* Guest Popup */}
          {guestMode && !adWatched && (
            <GuestPopup>
              <GuestViewText>View Today’s Selection</GuestViewText>
              <ButtonWrapper>
                <WatchAdButton onClick={handleWatchAd}>Watch Ad</WatchAdButton>
                <SOTDPlusButton onClick={handleSOTDPlusClick}>SOTD+</SOTDPlusButton>
              </ButtonWrapper>
              <FeatureList>
                <FeatureListItem>Full access to SOTD database</FeatureListItem>
                <FeatureListItem>Real-time entry and exit points</FeatureListItem>
                <FeatureListItem>AI analysis</FeatureListItem>
                <FeatureListItem>Ad free</FeatureListItem>
              </FeatureList>
            </GuestPopup>
          )}
        </>
      )}
      
      {/* Video Ad Popup */}
      <AdContainer show={showAd}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=uXlWYZ022zU"
          playing
          controls
          onEnded={handleAdEnded}
          width="80%"
          height="400px"
        />
        <p>Watch the full ad to continue</p>
      </AdContainer>
    </PageContainer>
  );
};



